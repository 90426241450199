import React from "react";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import Header from "./components/Header";
import PlayContent from "./components/PlayContent";
import Footer from "./components/Footer";
import PfpGenerator from "./PfpGenarator"; // Ensure this is imported correctly

import sky from "./assets/sky.png";
import trees from "./assets/trees.png";
import smoke from "./assets/smoke.png";
import rockL from "./assets/rock-left.png";
import rockR from "./assets/rock-right.png";
import copyBg from "./assets/copy-bg.png";
import faqBg from "./assets/TEAM.png";

function App() {
  return (
    <Router>
      <ParallaxProvider>
        <Root>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/generator" element={<PfpGenerator />} />
          </Routes>
        </Root>
      </ParallaxProvider>
    </Router>
  );
}

const handleCopy = () => {
  navigator.clipboard
    .writeText("BDd1bDsWkcbiG271CXN4uDP1ZgsQMSjfKdz7RrVipump")
    .then(() => console.log("copied")) // Notification changed to console log
    .catch((err) => console.error("Failed to copy text: ", err));
};

const Home = () => (
  <>
    <div className="landing">
      <Header />
      <div
        className="focus"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h2>
          Welcome to <span>PixelCatz</span>
        </h2>
        <h4>JOIN the fun!</h4>
        <button
          className="copy-area"
          onClick={handleCopy}
          style={{
            /* backgroundImage: `url(${copyBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%", */
            padding: 20,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:'#FFC700',
            borderWidth:3,
            borderColor:'#FFD953',
            lineBreak:'anywhere',
            gap:10
          }}
        >
          <span> 
          <strong className="l">CA:</strong>{" "}BDd1bDsWkcbiG271CXN4uDP1ZgsQMSjfKdz7RrVipump </span>
          <div className="icon" style={{marginLeft:10}}>
          <svg
            width="20"
            height="25"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0.5H8.25V2H1.5V11.75H0V0.5ZM3 3.5H12V15.5H3V3.5ZM4.5 5V14H10.5V5H4.5Z"
              fill="#2A2639"
            />
          </svg>
          </div>
        </button>
      </div>
      <Parallax className="bg sky" speed={-10}>
        <img src={sky} alt="Sky" />
      </Parallax>
      <Parallax className="bg smoke" speed={10}>
        <img src={smoke} alt="Smoke" />
      </Parallax>
      <Parallax className="bg trees" speed={8}>
        <img src={trees} alt="Trees" />
      </Parallax>
      <Parallax className="bg rockL" speed={-4}>
        <img src={rockL} alt="Rock Left" />
      </Parallax>
      <Parallax className="bg rockR" speed={-4}>
        <img src={rockR} alt="Rock Right" />
      </Parallax>
    </div>

    <PlayContent />
    <div
      className="faq-ctr"
      style={{
        backgroundImage: `url('${faqBg}')`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
    <Footer />
  </>
);

const Root = styled("div")`
  .copy-area {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    width:max-content;
    margin 0 auto;
    transition: transform 0.2s ease-in-out;
    height:100%;
    max-width:90%;
    position:relative;

    &::after{
      content:"";
      width:4px;
      height:90%;
      top:50%;
      position:absolute;
      background-color:#FFC700;
      left:100%;
      transform:translateY(-50%);
      border-right:3px solid #FFD953;
      border-bottom:3px solid #FFD953;
      border-top:3px solid #FFD953;
    }
      &::before{
      content:"";
      width:4px;
      height:90%;
      top:50%;
      position:absolute;
      background-color:#FFC700;
      right:100%;
      transform:translateY(-50%);
      border-left:3px solid #FFD953;
      border-bottom:3px solid #FFD953;
      border-top:3px solid #FFD953;
    }

    &:active {
          transform: scale(0.8); // Tıklandığında küçültme efekti
    }
  }

  & > .landing {
    width: 100%;
    position: relative;
    @media (min-width: 908px) {
      min-height: 100vh;
    }
    & > .focus {
      display: flex;
      flex-direction: column;
      text-align: center;
      position: relative;
      z-index: 3;
      opacity: 0;
      animation: fadeIn 1s ease normal forwards 1 1000ms;
      & > h2 {
        color: white;
        text-transform: uppercase;
        font-family: "Dogica Pixel";
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 150px;
        font-weight: 400;

        @media(min-width:992px){
          font-size: 64px; 
        }

        & span {
          color: #ffc700;
        }
      }
      & > h4 {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        color: #828282;
      }
      & > .play {
        background: #ffc700;
        color: #0a0a14;
        padding: 17px 35px 14px;
        border-radius: 4px;
        box-shadow: 0px 4px 0px #b07400;
        width: max-content;
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 50px;
      }
    }
    & > .bg {
      top: 0;
      width: 100%;
      position: absolute;
      opacity: 0;
      animation: fadeIn 1s ease normal forwards 1 500ms;
      & > img {
        width: 100%;
      }
      &.sky {
        margin-top: 10px;
        mask-image: linear-gradient(
          transparent 0%,
          black 5% 50%,
          transparent 100%
        );
      }
      &.smoke {
        margin-top: 90px;
        mask-image: linear-gradient(transparent 0%, black 30%);
      }
      &.trees {
        margin-top: 90px;
        mask-image: linear-gradient(transparent 0%, black 30%);
      }
      &.rockL,
      &.rockR {
        top: 100px;
      }
    }
  }
`;

export default App;
