import styled from "@emotion/styled";


import logo from '../assets/logo.png';
import hatt from '../assets/hatt.png';
import telegram from '../assets/telegram.png';
import twitter from '../assets/twitter.png';
import pumpfun from '../assets/pumpfun.png';

const Header = () => {
    return (
        <Root>
            <div className="left">
                <img src={logo} alt="logo" className="logo" />
                <a href="/" className="nav-link">Home</a>
                <a href="/#faq" className="nav-link">Faq</a>
                <a href="/generator" className="nav-link">PFP Generator</a>
            </div>


            <div className="right">
                <div className="socials">
                    <a href="https://x.com/PixelCatzSolana" target="_blank" rel="noreferrer">
                        <img src={twitter} alt="" />
                    </a>
                    <a href="https://t.me/PixelCatzSolana" target="_blank" rel="noreferrer">
                        <img src={telegram} alt="" />
                    </a>
                    <a href="https://pump.fun/coin/BDd1bDsWkcbiG271CXN4uDP1ZgsQMSjfKdz7RrVipump" target="_blank" rel="noreferrer">
                        <img src={pumpfun} alt="" />
                    </a>
                </div>

              
                <a className="btn-top" href="https://dexscreener.com" target="_blank">
                    <img src={hatt} alt="" />
                </a>
            </div>
        </Root> 
    )
}

const Root = styled('div')`
    display: flex;
    align-items: center;
    justify-content:center;
    flex-wrap:wrap;
    padding: 20px 40px;
    padding-bottom:0;
    position: relative;
    z-index: 10;
    opacity: 0;
    animation: fadeIn 1s ease normal forwards 1;
    zoom:.7;

    @media(min-width:908px){
        justify-content:space-between;
        zoom:1;
    }

    .left{
        display:flex;
        justify-content:flex-start;
        align-items:center;
    }
    .left > .logo {
        margin-right: 30px;
        height:22px;
    }
    .right{
        display:flex;
        justify-content:center;
        align-items:flex-end;
    }
    .left .nav-link{
        display: inline-block;
        color: #828282;
        font-size: 13px;
        font-weight:300;
        padding: 0 15px;
        white-space: nowrap;
        cursor: pointer;
        transition: 333ms ease;
        &:hover {
            color: #FFD953;
        }
        text-decoration: none; // Remove underline from links
    }
    .right > .socials {
        display: flex;
        align-items: center;
        justify-content:flex-end;
        gap: 30px;
        margin-right: 20px;
        height:40px;

        & > a {
            color: white; // Set the color for the link
            text-decoration: none; // Remove underline from links

            & > img {
                cursor: pointer;
                transition: 333ms ease;
                width:20px;
            }
        }
    }
    .btn-top{
        display:flex;
        justify-content:center;
        align-items:center;
        background:transparent;
        cursor:pointer;
        border:none;
        outline:none;

        & img{
            height:60px;
        }
    }
    
`

export default Header;
